<template>
  <div class="userInfo">
    <van-nav-bar title="个人资料" left-arrow @click-left="onClickLeft">
      <div v-if="type === 'edit'" slot="right" class="editSave">
        <van-button type="primary" size="small" @click="onSubmit"
          >保存</van-button
        >
      </div>
    </van-nav-bar>
    <div v-if="type === 'readOnly'">
      <van-cell-group class="group">
        <van-cell title="头像" class="cell-center">
          <div>
            <van-image class="user-info-avatar" :src="userInfo.avatar" />
          </div>
        </van-cell>
      </van-cell-group>
      <van-cell-group class="group">
        <van-cell title="昵称" :value="userInfo.nickname" />
        <van-form @submit="onSubmit">
        <van-field
          v-model="form.mobile"
          label="手机号"
          placeholder="请输入手机号"
          input-align="right"
          required
          :rules="[
          { required: true, message: '请填写手机号' },
           {pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/, message: '请输入正确的手机号'}]"
        />
        <van-field
          label="等级"
          input-align="right"
        >
        <template #input>
          <div>
            {{formatLevelName(userInfo.level)}}
          </div>
        </template>
        </van-field>
        <div style="margin:30px 16px 0">
          <van-button round block type="primary" native-type="submit" 
            >提交</van-button
          >
        </div>
      </van-form>
        
      </van-cell-group>
    </div>
    <div v-if="type === 'edit'" class="editSty">
      <van-cell>
        <input type="text" :value="editValue" />
      </van-cell>
    </div>
  </div>
</template>

<script>
import { Dialog ,Toast } from "vant";
import { getUserInfo, save2User } from "@/api/user";
import { getBankList } from "@/api/user";
import BankAddressSelector from "@/components/BankAddressSelector";

export default {
  name: "userInfo",
  components: { BankAddressSelector },
  data() {
    return {
      type: "readOnly", //只读readOnly    编辑edit
      editValue: "",
      editType: "",
      userInfo: {},
      bankList: [],
      showPicker: false,
      inputType: "password",
      form: {
        name: "", // 真实姓名
        mobile: "", // 手机号码
        bank: "", // 开户行
        bankName: "",
        // subBank: '', // 开户支行
        bankProvince: "", // 银行所在省
        bankProvinceName: "",
        bankCity: "", // 银行所在市
        bankCityName: "",
        bankAccountNo: "", // 银行卡号
        idNum: "", // 身份证号
      },
      saving: false,
    };
  },
  async created() {
    await getUserInfo().then((res) => {
      this.userInfo = res || {};
      this.form = [
        {},
        // "name",
        "mobile",
        "nickname",
        // "bank",
        // "bankName",
        // "bankProvince",
        // "bankProvinceName",
        // "bankCity",
        // "bankCityName",
        // "bankAccountNo",
        // "idNum",
      ].reduce((obj, key) => {
        obj[key] = this.userInfo[key] || "";
        return obj;
      });
    });
    getBankList().then((res) => {
      this.bankList = res || [];
      let bankName = "";
      for (let bank of this.bankList) {
        if (bank.value === this.userInfo.bank) {
          bankName = bank.label;
          break;
        }
      }
      this.form.bankName = bankName;
    });
  },
  methods: {
    formatLevelName(level) {
      return {
        1: "普通用户",
        2: "居间人",
        3: "推广员",
        4: "店铺",
      }[level];
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
    },
    onClickLeft() {
      console.log(this.form);
      // type:'readOnly',   //只读readOnly    编辑edit
      if (this.type === "readOnly") {
        this.$router.back();
      } else {
        this.type = "readOnly";
      }
    },
    editUser(type, value) {
      this.type = "edit";
      this.editValue = value;
      this.editType = type;
      if (type === "nickname") {
        this.form.nickname = value;
      } else {
        this.form.mobile = value;
      }
    },
    onSubmit() {
      this.saving = true;
      save2User({ ...this.form }, { headers: { getAllResponse: true } }).then(
        (res) => {
          console.log("保存", res);
          if (res === true) {
            window.localStorage.setItem(
              "userInfoReSubmitCountDown",
              Date.now() + 30 * 1000
            );
            this.reSubmitCountDownTime = 30 * 1000;
            Toast("保存成功");
            this.$router.back();
          }
          this.saving = false;
        }
      );
   
      return
      this.saving = true;
      saveUser({ ...this.form }, { headers: { getAllResponse: true } }).then(
        (res) => {
          console.log("保存", res);
          if (res === true) {
            window.localStorage.setItem(
              "userInfoReSubmitCountDown",
              Date.now() + 30 * 1000
            );
            this.reSubmitCountDownTime = 30 * 1000;
            Toast("个人信息保存成功");
            this.$router.back();
          }
          this.saving = false;
          if (res.data.code == 0) {
            return;
          }
          Dialog.alert({
            title: "请签署协议",
            // message: res.data.contractList[0].contractTitle,
            message: res.data.contractList
              .map((it) => it.contractTitle)
              .join("\n"),
          }).then(() => {
            // on close
            this.iframeUrl = res.data.contractList[0].shortUrl;
            //  console.log(' this.iframeUrl', this.iframeUrl);
            this.$router.push({
              path: "/userInfoUrl",
              query: { iframeUrl: this.iframeUrl },
            });
            // userInfoUrl
          });
        }
      );
    },
  },
};
</script>

<style scoped lang='less'>
.userInfo {
  background-color: #f3f3f3;
  .group {
    margin-top: 10px;
    .cell-center {
      display: flex;
      align-items: center;
    }
  }
  .editSty {
    margin-top: 10px;
  }
  .editSave {
    font-size: 16px;
    color: #212121;
  }
}

.user-info-avatar {
  width: 40px;
  height: 40px;
}
::v-deep .van-nav-bar {
  background-color: #f8f8f8;
  .van-icon {
    color: #212121;
    font-size: 18px;
  }
}
.van-cell__value {
  color: #323233;
}
.van-cell__title {
  color: #999999;
}
::v-deep .van-form{
  background-color: #f6f6f6;
}
</style>